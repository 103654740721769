import React from 'react';
import './App.css';
import CheckToken from './model/checkToken';
import { RouteComponentProps } from 'react-router';

type urlProps = {} & RouteComponentProps<{token: string}>;

const Entry: React.FC<urlProps> = (props) => {
  return (
    <div className="msgBox">
      <p>Payeeをインストールしたスマートフォン端末よりアクセスしてご利用ください。</p>
    </div>
  );
};
export default Entry;
